import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Home from './containers/home'
import MenuPage from './containers/menu_page'
import MenuItems from './containers/menu_items'
import Page from './containers/page'
import Post from './containers/post'
import Blog from './containers/blog'
import Locations from './containers/locations'
import NotFound from './containers/not_found'
import GCBalance from './containers/gift-card-balance'

const RouteFunctor = [
  {
    path: '/Home',
    component: props => <Redirect to={'/'} />
  },
  {
    path: '/uploads/nutritional-allergen_spreads_02-18-2.pdf',
    component: props => <Redirect to={'/nutrition'} />
  },
  { path: '/', component: Home, exact: true },
  {
    path: '/index.php?p_resource=nutrition',
    component: props => <Redirect to={'/nutrition'} />
  },
  {
    path: '/About',
    component: props => <Redirect to={'/our-story'} />
  },
  {
    path: '/Join-the-Family',
    component: props => <Redirect to={'/our-story/join-our-family'} />
  },
  {
    path: '/Executive-Bios',
    component: props => <Redirect to={'/our-story/executive-bios'} />
  },
  {
    path: '/Cookbook',
    component: props => <Redirect to={'/blog'} />
  },
  {
    path: '/Donate',
    component: props => <Redirect to={'/giving/donation-request'} />
  },
  {
    path: '/Contact',
    component: props => <Redirect to={'/contact-us'} />
  },
  {
    path: '/Franchise',
    component: props => <Redirect to={'/our-story/join-our-family'} />
  },
  {
    path: '/Franchise-Questions',
    component: props => <Redirect to={'/our-story/join-our-family'} />
  },
  {
    path: '/Franchise-Growth',
    component: props => <Redirect to={'/our-story/join-our-family'} />
  },
  {
    path: '/Franchise-Steps',
    component: props => <Redirect to={'/our-story/join-our-family'} />
  },
  {
    path: '/Franchise-Interest',
    component: props => <Redirect to={'/our-story/join-our-family'} />
  },
  {
    path: '/Media',
    component: props => <Redirect to={'/press'} />
  },
  {
    path: '/Press-Awards',
    component: props => <Redirect to={'/press'} />
  },
  {
    path: '/uploads/TandC_social.pdf',
    component: props => <Redirect to={'/privacy-policy-legal'} />
  },
  {
    path: '/uploads/TandC_social2.pdf',
    component: props => <Redirect to={'/privacy-policy-legal'} />
  },
  {
    path: '/donations',
    component: props => <Redirect to={'/giving/donation-request'} />
  },
  {
    path: '/frequent-diner',
    component: props => <Redirect to={'/rewards'} />
  },
  {
    path: '/hope',
    component: props => <Redirect to={'/'} />
  },
  {
    path: '/fun',
    component: () => (window.location = 'https://www.eventbrite.com/o/kneaders-bakery-amp-cafe-64186199133'),
  },
  // {
  //   path: '/hope',
  //   component: () => (window.location = 'https://hope.kneaders.com'),
  // },

  { path: '/menu/:category/:item_slug', component: MenuItems },
  { path: '/menu/:category', component: MenuItems },
  { path: '/menu', component: MenuPage },
  { path: '/locations', component: Locations },
  { path: '/blog/:slug', component: Post },
  { path: '/blog', component: Blog },
  { path: '/gift-card-balance', component: GCBalance },
  { path: '/:slug/:sub_page_slug', component: Page },
  { path: '/:slug', component: Page },
  { component: NotFound }
]

const RouteActor = route => {
  return (
    <Route
      exact={route.exact}
      path={route.path}
      render={props => {
        props.history.listen(() => window.scroll(0, 0))

        return (
          <route.component
            {...props}
            routes={route.sub_routes}
            geolocation={route.geolocation}
          />
        )
      }}
    />
  )
}

export { RouteActor, RouteFunctor }
